<template>
  <div class="contents">
    <div class="contents_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="contents_dnf" v-if="show">
      <discussion-list @Topic-DataGuid="DataGuidClick" :key="DataGuidId"></discussion-list>
    </div>
    <div class="contents_dnf" v-show="!show">
      <div class="contents_dnf1">
        <div class="contents_right">
          <div class="content-user">
            楼主
          </div>
          <div class="content-BBsName">
            <img src="../../../assets/image/taskmanagement/projectname.png" alt="">
            <span class="span_18" v-if="obj.BBSName">{{obj.BBSName}}</span>
          </div>
          <div style="display: flex;margin-left: 26px;margin-top: 4px;">
            <div style="margin-right: 30px">
              <img src="../../../assets/image/taskmanagement/projectleader.png" alt="">
              <span>帖子创建作者:</span>
              <span>{{userName}}</span>
<!--              <span>{{obj.UserName}}</span>-->
            </div>
            <div>
              <img src="../../../assets/image/taskmanagement/time.png" alt="">
              <span>帖子创建时间:</span>
              <span>{{obj.UpdateDate}}</span>
            </div>
          </div>
          <div style="position: absolute;right: 20px;top: 30px">
            <el-button @click="show = true" type="primary">返回上一级</el-button>
          </div>
          <div class="reply-content">
            <el-input
              class="content-BBSText"
              readonly
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3}"
              v-model="obj.BBSText">
            </el-input>
            <div @click="reply" class="reply-icon">回复TA</div>
          </div>
        </div>
      </div>
      <div style="height: 500px;overflow-y: scroll;overflow-x: hidden">
        <div class="reply-content-2" v-for="(item,index) in datalist" :key="index">
          <div class="reply-user">
            <p>{{item.UserName}}</p>
          </div>
          <div class="reply-content-time">
            <div style="display: flex;justify-content: space-between">
              <div>
                <img src="../../../assets/image/taskmanagement/time.png" alt="">
                <span>发表时间:</span>
                <span>{{item.UpdateDate}}</span>
              </div>
              <div>
                <el-link style="font-size: 18px;color:#3978E7;font-weight:400;margin-right: 21px" :underline="false" icon="el-icon-document-copy" @click="hui(item.Id)">回复</el-link>
                <el-link style="font-size: 18px;color:#3978E7;font-weight:400;margin-right: 21px" :underline="false" icon="el-icon-edit" @click="Modifypost(item.Id,item.BBSText)">修改</el-link>
                <el-link style="font-size: 18px;color:#3978E7;font-weight:400;margin-right: 10px" :underline="false" icon="el-icon-delete" @click="delpost(item.Id)">删除</el-link>
              </div>
            </div>
            <el-input
              class="content-BBSText"
              readonly
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 3}"
              v-model="item.BBSText">
            </el-input>
            <div class="reply-content-child">
              <div v-for="(item1,i) in item.BBSChildList" :key="i">
                <el-tooltip placement="top">
                  <div slot="content">
                    <p class="tooltip-content">{{item1.BBSText}}</p>
                  </div>
                  <span class="child-span">{{item1.UserName}}:{{item1.BBSText}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <div class="discussionArea-dialogVisible">
    <el-dialog
        :visible.sync="dialogVisible"
        v-dialogDrag
       >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">回复</span>
      </div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="回复内容:">
          <el-input type="textarea" v-model="BBSText" placeholder="请输入"></el-input>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="onSubmit">立即回复</el-button>-->
<!--          <el-button @click="oncancel">取消</el-button>-->
<!--        </el-form-item>-->
      </el-form>
      <div class="btn">
        <button  @click="oncancel">取消</button>
        <button  @click="onSubmit">确定</button>
      </div>
    </el-dialog>
    </div>
    <div class="discussionArea-dialogVisible">
    <el-dialog
        :visible.sync="dialogVisible1"
        v-dialogDrag
        >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改回复</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="回复内容:">
          <el-input type="textarea" v-model="BBSText" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button  @click="oncancel1">取消</button>
        <button  @click="onSubmit1">确定</button>
      </div>
    </el-dialog>
    </div>
    <el-dialog
        :visible.sync="dialogVisible2"
        v-dialogDrag
       >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">回复</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="回复内容:">
          <el-input type="textarea" v-model="bbs" placeholder="请输入" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button  @click="jie">取消</button>
        <button  @click="kai">确定</button>
      </div>
    </el-dialog>
    <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="handleDelDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该回复，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="handleDelDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DiscussionList from "@/components/DiscussionList";
import research from "../../../api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import reseacrh from "@/api/research";
export default {

  data(){
    return{
      DataGuidId: "1", // 默认key的值
      userName: "",
      delID: null, // 删除回复的Id
      handleDelDialog: false,//删除弹框
      show: true,
      BBSText:"",
      bbs:"",
      dialogVisible:false,
      dialogVisible1:false,
      dialogVisible2:false,
      obj:{},
      datalist:[],
      tid:"",
      id:"",
      DataId: "",
      PageCount: 0,
      currentPage: 1
    }
  },
  created(){
  },
  computed: mapState(["projectId","DataGuid"]),
  components:{
    treedata,
    DiscussionList
  },
  methods:{
    projectToPicInfo() { //
      reseacrh.topic(this.projectId).then(res => {
        this.$store.commit("Guid", res.data.ExtraData.DataGuid);
        this.DataGuidId = res.data.ExtraData.DataGuid
      })
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.show = true
      this.$store.commit('SaveId',data)
      this.projectToPicInfo()
    },
    DataGuidClick(data) { //讨论区列表传递过来的id
      this.DataId = data.Id
      this.userName = data.UserName
      this.show = false
      this.postreply()
      this.discuss()
    },
    discuss() {
      research.numPosts(this.DataId).then(res=>{//单个帖子数据
        this.obj=res.data.ExtraData
      })
    },
    jie(){
      this.dialogVisible2=false
    },
    kai(){
      var replobj1={
        TopicBBSId:this.DataId,
        BBSText:this.bbs,
        QuoteId:this.id,
      }
      research.replyPosts(replobj1).then(res=>{
        if(res.data.Status==1){
          this.$message.success('回复成功')
          this.postreply()
          this.bbs=""
          this.dialogVisible2=false
        }
      })
    },
    hui(id){
      this.id=id
      this.dialogVisible2=true
    },
    handleCurrentChange(val){ // 分页
      this.currentPage=val
      this.postreply()
      console.log(`当前页: ${val}`);
    },
    postreply(){//回复帖子的数据
      var postobj={
        TopicBBSId:this.DataId,
        BBSText:"",
        PageIndex:this.currentPage-1,
        PageSize:10,
      }
      research.postPosts(postobj).then(res=>{
        if(res.data.Status==1){
          this.datalist=res.data.Entity
          this.PageCount=res.data.TotalRowsCount
          // console.log(res,"回复帖子的数据");
        }
      })
    },
    rttpl(){
      this.$router.push({name:"paratext"})
    },
    reply(){//打开回复帖子按钮
      this.dialogVisible=true
    },
    onSubmit(){//确认回复帖子
      var replobj={
        TopicBBSId:this.DataId,
        BBSText:this.BBSText,
        QuoteId:0,
      }
      research.replyPosts(replobj).then(res=>{
        if(res.data.Status==1){
          this.$message.success("回复成功")
          this.postreply()
          this.BBSText=""
          this.dialogVisible=false
        }
      })
    },
    oncancel(){//关闭确认回复帖子按钮
      this.BBSText=""
      this.dialogVisible=false
    },
    Modifypost(id,BBSText){//打开修改帖子弹框
      this.BBSText=BBSText
      this.tid=id
      this.dialogVisible1=true
    },
    onSubmit1(){//确认修改帖子
      var upobj={
        Id:this.tid,
        BBSText:this.BBSText,
      }
      research.UpdatePosts(upobj).then(res=>{
        if(res.data.Status==1){
          this.$message.success("修改成功")
          this.BBSText=""
          this.tid=""
          this.postreply()
          this.dialogVisible1=false
        }
      })
    },
    oncancel1(){//关闭修改回复弹框
      this.BBSText="",
      this.tid=""
      this.dialogVisible1=false
    },
    submitDel() {
      research.DeletePosts({Id:this.delID}).then(res=>{
        if(res.data.Status==1){
          this.postreply()
          this.handleDelDialog = false
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    delpost(id){
      this.handleDelDialog = true
      this.delID = id
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../styles/discussion_area.scss";
</style>

<template>
  <div class="paratext">
    <div class="paratext_div1">
      <el-button type="primary" icon="el-icon-plus" @click="addPost">创建帖子</el-button>
    </div>
    <div class="paratext_div2">
      <div style="height: 660px;">
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="660"
          border
          fit
          highlight-current-row
          :header-cell-style="{
                color: '#333333',
                backgroundColor: '#E9E9E9',
              }"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="65"
          >
          </el-table-column>
          <el-table-column
            prop="BBSName"
            label="标题"
            align="center"
           >
            <template slot-scope="scope">
              <span style="cursor:pointer" :underline="false" @click="see(scope.$index, scope.row)">{{scope.row.BBSName}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="UserName"
            label="创建人"
            align="center"
            width="170">
          </el-table-column>
          <el-table-column
            prop="CreateDate"
            label="创建时间"
            align="center"
            width="190">
          </el-table-column>
          <el-table-column
            prop="Reply"
            align="center"
            label="回复数"
            width="120">
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template slot-scope="scope">
              <el-link
                style="font-size:16px;color: #3978E7;font-weight:400;margin-right: 11px"
                type="text"
                :underline="false"
                @click="handleEdit(scope.$index, scope.row)"
              ><i class="el-icon-edit-outline"></i>编辑</el-link>
              <el-link
                style="font-size:16px;color: #3978E7;font-weight:400;margin-right: 11px"
                type="text"
                :underline="false"
                @click="see(scope.$index, scope.row)"
              ><img src="../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -4px">查看</el-link>
              <el-link
                style="font-size:16px;color: #3978E7;font-weight:400;margin-right: 11px"
                type="text"
                :underline="false"
                @click="handleDelete(scope.$index, scope.row)"
              ><i class="el-icon-delete"></i>删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="13"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">发布帖子</span>
      </div>
      <el-form ref="form" label-width="90px">
        <el-form-item label="帖子标题:">
          <el-input v-model="BBSName"></el-input>
        </el-form-item>
        <el-form-item label="帖子时间:">
          <el-date-picker
            v-model="BBSDate"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="帖子内容:">
          <el-input type="textarea" v-model="BBSText" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button  @click="dialogVisible=false">取消</button>
        <button  @click="onSubmit">确定</button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible1"
    >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改帖子</span>
      </div>
      <el-form ref="form" label-width="100px">
        <el-form-item label="帖子标题:">
          <el-input v-model="BBSName"></el-input>
        </el-form-item>
        <el-form-item label="帖子内容:">
          <el-input type="textarea" v-model="BBSText" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div class="btn">
        <button  @click="dialogVisible1 = false">取消</button>
        <button type="primary" @click="onSubmit1">确定</button>
      </div>
    </el-dialog>
    <div class="withdraw">
      <el-dialog
        top="35vh"
        :visible.sync="handleDelDialog"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该帖子，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="handleDelDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import research from "../api/research.js"
export default {
  data(){
    return{
      delItem: {}, //要删除的对象
      handleDelDialog: false,//删除弹框
      dialogVisible:false,
      dialogVisible1:false,
      BBSName:"",
      BBSText:"",
      BBSDate:"",
      Site:"",
      tableData: [],
      tiid:"",
      PageCount: 0,
      currentPage: 1
    }
  },
  created(){
    this.listfen()
  },
  methods:{
    see(index,item){
      this.$emit('Topic-DataGuid', item) // 传递数据
      // this.$router.push({name:"preExperiment",params:{id:item.Id,usename:item.UserName}})
    },
    listfen(){
      var listobj={
        DataGuid:this.$store.state.DataGuid,
        BBSName:"",
        PageIndex:this.currentPage-1,
        PageSize:13,
      }
      research.listPosts(listobj).then(res=>{//帖子列表
        if(res.data.Status==1){

          this.tableData=res.data.Entity
          this.PageCount=res.data.TotalRowsCount
          console.log(res);
        }else{
          alert(res.data.Message)
        }
      })
    },
    onSubmit1(){//确定修改帖子
      var editobj={
        Id:this.tiid,
        BBSName:this.BBSName,
        BBSText:this.BBSText,
      }
      research.editPosts(editobj).then(res=>{
        if(res.data.Status==1){
          this.dialogVisible1=false
          this.$message.success("帖子修改成功")
          this.listfen()
        } else {
          this.$message.error(res.data.Message)
        }
        console.log(res);
      })
    },
    handleEdit(index,item){//打开修改帖子弹框
      console.log(index,item);
      this.tiid=item.Id
      this.BBSName=item.BBSName
      this.BBSText=item.BBSText
      this.dialogVisible1=true
    },
    addPost(){//打开创建帖子列表
      this.BBSName = ""
      this.BBSText = ""
      this.BBSDate = ""
       this.Site = ""
      this.dialogVisible=true
    },
    handleCurrentChange(val){ // 分页
      this.currentPage=val
      this.listfen()
      console.log(`当前页: ${val}`);
    },
    onSubmit(){//确定新增帖子
      console.log(this.$store.state.DataGuid,this.BBSName,this.BBSText,this.BBSDate,this.Site);
      var objpost={
        PDataGuid:this.$store.state.DataGuid,
        BBSName:this.BBSName,
        BBSText:this.BBSText,
        BBSDate:this.BBSDate,
        Site:this.Site
      }
      research.addPosts(objpost).then(res=>{
        if(res.data.Status==1){
          this.dialogVisible=false
          this.$message.success("新增帖子成功")
          this.listfen()
        }
        console.log(res);
      })
    },
    submitDel() {
      research.deletePosts({Id:this.delItem.Id}).then(res=>{
        if(res.data.Status==1){
          this.handleDelDialog = false
          this.listfen()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      })
    },
    handleDelete(index,item){
      this.handleDelDialog = true
      this.delItem = item
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/discussion_list.scss";
</style>
